import React from 'react';
import Footer from './Footer';
import Header from './Header';

import { Link } from 'gatsby';
import CookieConsent from "react-cookie-consent";

const Layout = ({ children }) => {
  return (
    <>
      <CookieConsent
            location="bottom"
            buttonText="Accept"
            declineButtonText="Decline"
            cookieName="fjweb_ga"
            // buttonStyle={{ background: '#7f1d1d', color: "white", fontSize: "15px", verticalAlign: 'middle' }}
            expires={150}
            onAccept={() => {
              if (typeof window !== 'undefined') { // window isn't defined during build
                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push({ event: 'gatsbyRouteChange' });
                console.log("route change pushed");
              } else {
                console.log("route change not pushed - window undefined")
              }
            }}
          >
              <span style={{fontSize: "0.75rem"}}>
            FloofJournal.com uses cookies to analyze website traffic and usage. By clicking "Accept", or by continuing to otherwise browse and 
						use this website, you agree to our use of cookies and similar technologies.
              </span>

      </CookieConsent>
      <Header />
      <main className="text-gray-900">{children}</main>
      <Footer />
    </>
  );
};

export default Layout;
