import React from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll';
// import LogoIcon from '../../images/LogoIcon';
import LogoIcon from '../../images/fjlogo.svg';
import Button from '../Button';
import { Link } from 'gatsby';

const AmazonLink = "https://www.amazon.com/s?k=floof+journal"

const Header = () => (
  <header className="sticky top-0 bg-white shadow">
    <div className="container flex flex-col sm:flex-row justify-between items-center mx-auto py-4 px-8">
      
      <div className="flex items-center text-2xl">
        <div className="w-12 mr-3">
          {/* <LogoIcon /> */}
          <AnchorLink href="#home">
            <img src={LogoIcon} />
          </AnchorLink>
          {/* <img src={LogoIcon} /> */}
        </div>
        <AnchorLink className="hdrTitle" href="#home">
          Floof Journal
        </AnchorLink>
      </div>

      <div className="flex mt-4 sm:mt-0">
        <AnchorLink className="px-4 menu" href="#features">
          Design
        </AnchorLink>
        <AnchorLink className="px-4 menu" href="#research">
          Research
        </AnchorLink>
        {/* <AnchorLink className="px-4 menu" href="#stats">
          Books
        </AnchorLink> */}
        <AnchorLink className="px-4 menu" href="#testimonials">
          Testimonials
        </AnchorLink>
        <AnchorLink className="px-4 menu" href="#pledge">
          Pledge
        </AnchorLink>
      </div>
      <div className="hidden md:block">
        <a className="menu" href={AmazonLink}
                target={"_blank"} rel="noreferrer" title={"Floof Journal on Amazon"}>
        <Button className="text-sm">Shop Now</Button>
      </a>

      </div>
    </div>
  </header>
);

export default Header;
